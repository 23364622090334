import Vue from 'vue';
import App from './App.vue';

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faStop, faStepForward, faPlay, faSuitcase, faPlus, faStopwatch, faFolderOpen,
  faSave, faPalette, faFileImport, faCrosshairs, faPen, faCode, faPause,
  faCheck, faInfinity,
} from '@fortawesome/free-solid-svg-icons'
import {
  faSquare
} from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

library.add(
  // solid
  faStop, faStepForward, faPlay, faSuitcase, faPlus, faStopwatch, faFolderOpen,
  faSave, faPalette, faFileImport, faCrosshairs, faPen, faCode, faPause,
  faCheck, faInfinity,
  // regular
  faSquare,
);

Vue.component('fa', FontAwesomeIcon)
Vue.component('fa-layers', FontAwesomeLayers)

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount('#app');
