<template>
  <div id="app">
    <Header />
    <div id="content">
      <CodePanel v-model="code" :editable="editable" />
      <WorldPanel ref="world_panel" :code="code" :state.sync="state" />
    </div>
  </div>
</template>

<script>
import Header from './components/Header.vue';
import CodePanel from './components/CodePanel.vue';
import WorldPanel from './components/WorldPanel.vue';
import Split from 'split.js';
import { RunningState } from './lib/constants.js';
import EditableMixin from './mixins/EditableMixin.js';

export default {
  name: 'app',

  mixins: [EditableMixin],

  data: function () {
    return {
      code: 'avanza',
      state: RunningState.CLEAN,
    };
  },

  components: {
    Header, CodePanel, WorldPanel
  },

  mounted: function () {
    Split(['#code-panel', '#world-panel'], {
      sizes: [30, 70],
      onDrag: () => {
        this.triggerRecalcDimentions();
      },
    });

    window.addEventListener('resize', () => {
      this.triggerRecalcDimentions();
    });
  },

  methods: {
    triggerRecalcDimentions() {
      this.$refs.world_panel.recalcCanvasDimentions();
    },
  },
}
</script>

<style lang="scss">
@import './styles/app.scss';
</style>
