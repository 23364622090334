export default function() {
  function words(str) {
    let obj = {}, words = str.split(' ');
    for (let i = 0; i < words.length; ++i) obj[words[i]] = true;
    return obj;
  }

  let keywords = words(
      'iniciar-programa finalizar-programa inicia-ejecucion inicia-ejecución termina-ejecucion termina-ejecución si sino si-no entonces repetir veces mientras hacer como sal-de-instruccion sal-de-instrucción define-nueva-instruccion define-nueva-instrucción define-prototipo-instruccion define-prototipo-instrucción');
  let indent = words(
      'iniciar-programa finalizar-programa inicia-ejecucion inicia-ejecución termina-ejecucion termina-ejecución inicio');
  let dedent = words('fin');
  let builtin = words(
      'coge-zumbador deja-zumbador gira-izquierda avanza apagate apágate');
  let operator = words('y e o u no si-es-cero precede sucede');
  let atoms = words(
      'frente-libre frente-bloqueado izquierda-libre izquierda-bloqueada derecha-libre derecha-bloqueada junto-a-zumbador no-junto-a-zumbador algun-zumbador-en-la-mochila algún-zumbador-en-la-mochila ningun-zumbador-en-la-mochila ningún-zumbador-en-la-mochila orientado-al-norte orientado-al-sur orientado-al-este orientado-al-oeste no-orientado-al-norte no-orientado-al-sur no-orientado-al-este no-orientado-al-oeste');

  function tokenBase(stream, state) {
    let ch = stream.next();
    if (ch == '{') {
      state.tokenize = tokenSimpleComment;
      return tokenSimpleComment(stream, state);
    }
    if (ch == '(' && stream.eat('*')) {
      state.tokenize = tokenComment;
      return tokenComment(stream, state);
    }
    if (/[();]/.test(ch)) {
      return null;
    }
    if (/\d/.test(ch)) {
      stream.eatWhile(/[\w.]/);
      return 'number';
    }
    stream.eatWhile(/[\wÀ-ÖØ-öø-ÿ_-]/);
    let cur = stream.current().toLowerCase();
    let style = 'variable';
    if (Object.prototype.propertyIsEnumerable.call(keywords, cur))
      style = 'keyword';
    else if (Object.prototype.propertyIsEnumerable.call(builtin, cur))
      style = 'builtin';
    else if (Object.prototype.propertyIsEnumerable.call(operator, cur))
      style = 'operator';
    else if (Object.prototype.propertyIsEnumerable.call(atoms, cur))
      style = 'atom';
    else if (Object.prototype.propertyIsEnumerable.call(indent, cur))
      style = 'indent';
    else if (Object.prototype.propertyIsEnumerable.call(dedent, cur))
      style = 'dedent';
    else if (state.lastTok == 'define-nueva-instruccion' ||
             state.lastTok == 'define-nueva-instrucción' ||
             state.lastTok == 'define-prototipo-instruccion' ||
             state.lastTok == 'define-prototipo-instrucción')
      style = 'def';
    state.lastTok = cur;
    return style;
  }

  function tokenSimpleComment(stream, state) {
    let ch = stream.next();
    while (ch) {
      if (ch == '}') {
        state.tokenize = null;
        break;
      }

      ch = stream.next();
    }
    return 'comment';
  }

  function tokenComment(stream, state) {
    let maybeEnd = false;
    let ch = stream.next();

    while (ch) {
      if (ch == ')' && maybeEnd) {
        state.tokenize = null;
        break;
      }
      maybeEnd = (ch == '*');

      ch = stream.next();
    }
    return 'comment';
  }

  // Interface

  return {
    startState: function() {
      return {
        tokenize: null,
        lastTok: null,
      };
    },

    token: function(stream, state) {
      if (stream.eatSpace()) return null;
      return (state.tokenize || tokenBase)(stream, state);
    },
  };
}
