<template>
  <div id="contextmenu" :style="menuStyle" >
    <div class="karel-position">
      <button class="btn" @click="hide(); $emit('set-karel-north')"><img src="../assets/karel-30-north.png" /></button>
      <button class="btn" @click="hide(); $emit('set-karel-south')"><img src="../assets/karel-30-south.png" /></button>
      <button class="btn" @click="hide(); $emit('set-karel-east')"><img src="../assets/karel-30-east.png" /></button>
      <button class="btn" @click="hide(); $emit('set-karel-west')"><img src="../assets/karel-30-west.png" /></button>
    </div>
    <div class="leyend">
      Poner zumbadores:
    </div>
    <div class="beeper-controls">
      <button class="btn" @click="hide(); $emit('set-zero-beepers')">0</button>
      <input type="text" placeholder="N" v-model="nBeepers" @keyup="handleNBeepers" />
      <button class="btn" @click="hide(); $emit('set-inf-beepers')">
        <fa icon="infinity" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContextMenu',

  props: {
    left: Number,
    top: Number,
  },

  data() {
    return {
      visible: false,
      width: 200,
      height: 120,
      nBeepers: "",
    };
  },

  computed: {
    menuStyle() {
      return {
        left: this.left+'px',
        top: this.top+'px',
        width: this.width+'px',
        height: this.height+'px',
        display: this.visible?'block':'none',
      };
    },
  },

  mounted() {
    document.addEventListener('keyup', this.handleEscape);
  },

  beforeDestroy() {
    document.removeEventListener('keyup', this.handleEscape);
  },

  methods: {
    handleEscape(event) {
      if (event.keyCode != 27) {
        return;
      }

      this.hide();
    },

    handleNBeepers(event) {
      if (event.keyCode != 13) {
        return;
      }

      try {
        this.$emit('set-n-beepers', parseInt(this.nBeepers))
      // eslint-disable-next-line
      } catch (e) { }

      this.hide();
      this.nBeepers = '';
    },

    show() {
      this.visible = true;
    },

    hide() {
      this.visible = false;
    },
  },
}
</script>

<style scoped lang="scss">
#contextmenu {
  position: absolute;
  bottom: 0;
  right: 0;
  padding:5px;
  border-radius: 5px;
  box-shadow: 12px 12px 2px 1px rgba(0, 0, 255, .2);
  font-weight: bold;

  .btn {
    font-weight: bold;
    border-radius: 3px;
  }

  .karel-position {
    display: flex;

    button {
      flex-grow: 1;
      height: 40px;
      margin-right: 5px;
      padding: 0px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .leyend {
    padding: 5px 0px;
  }

  .beeper-controls {
    display: flex;

    input {
      width: 50px;
      margin-right: 5px;
      text-align: center;
      border-radius: 3px;
      border: 0;
    }

    button {
      flex-grow: 1;
      height: 40px;
      margin-right: 5px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}
</style>
