import Runtime from './Runtime.js';

class World {
  constructor(w, h) {
    this.init(w, h);
  }

  createMaps() {
    if (ArrayBuffer) {
      let len = (this.w + 2) * (this.h + 2);
      this.map = new Int32Array(new ArrayBuffer(len * 4));
      this.currentMap = new Int32Array(new ArrayBuffer(len * 4));
      this.wallMap = new Uint8Array(new ArrayBuffer(len));
    } else {
      this.map = [];
      this.currentMap = [];
      this.wallMap = [];
      for (let i = 0; i <= this.h; i++) {
        for (let j = 0; j <= this.w; j++) {
          this.map.push(0);
          this.currentMap.push(0);
          this.wallMap.push(0);
        }
      }
    }

  }

  init(w, h) {
    this.w = w;
    this.h = h;
    this.runtime = new Runtime(this);
    this.createMaps();

    this.clear();
  }

  resize(w, h) {
    // Eliminamos las paredes del borde
    for (let i = 1; i <= this.h; i++) {
      this.wallMap[this.w * i + 1] &= ~(1 << 0);
      this.wallMap[this.w * (i + 1)] &= ~(1 << 2);
    }
    for (let j = 1; j <= this.w; j++) {
      this.wallMap[this.w * this.h + j] &= ~(1 << 1);
      this.wallMap[this.w + j] &= ~(1 << 3);
    }

    let oldW = this.w;
    let oldH = this.h;
    let oldMap = this.map;
    let oldWallMap = this.wallMap;
    let oldDumpCells = this.dumpCells;

    this.w = w;
    this.h = h;
    this.createMaps();
    this.addBorderWalls();

    // Copiamos todas las paredes y zumbadores
    for (let i = 1; i <= oldH; i++) {
      for (let j = 1; j <= oldW; j++) {
        this.setCellWalls(i, j, oldWallMap[oldW * i + j]);
        this.setBuzzers(i, j, oldMap[oldW * i + j]);
      }
    }

    // Vaciamos dumpCells y la llenamos de nuevo
    this.dumpCells = [];
    for (let dumpPos = 0; dumpPos < oldDumpCells.length; dumpPos++) {
      if (oldDumpCells[dumpPos][0] <= this.h &&
          oldDumpCells[dumpPos][1] <= this.w) {
        this.setDumpCell(oldDumpCells[dumpPos][0], oldDumpCells[dumpPos][1],
                         true);
      }
    }

    // Checamos si karel sigue dentro del mundo
    if (this.start_i > this.h) this.start_i = this.i = this.h;
    if (this.start_j > this.w) this.start_j = this.j = this.w;

    this.dirty = true;
  }

  clear() {
    for (let i = 0; i < this.wallMap.length; i++) {
      this.wallMap[i] = 0;
    }

    for (let i = 0; i < this.map.length; i++) {
      this.map[i] = this.currentMap[i] = 0;
    }

    this.addBorderWalls();

    this.orientation = 1;
    this.startOrientation = 1;
    this.start_i = 1;
    this.i = 1;
    this.start_j = 1;
    this.j = 1;
    this.startBagBuzzers = 0;
    this.bagBuzzers = 0;
    this.dumps = {};
    this.dumpCells = [];
    this.maxInstructions = 10000000;
    this.maxMove = -1;
    this.maxTurnLeft = -1;
    this.maxPickBuzzer = -1;
    this.maxLeaveBuzzer = -1;
    this.maxKarelBeepers = -1;
    this.maxBeepers = -1;
    this.maxStackSize = 65000;
    this.worldName = 'mundo_0';
    this.programName = 'p1';
    this.preValidators = [];
    this.postValidators = [];

    this.dirty = true;
  }

  validate() {
    throw 'not imported';
  }

  preValidate(callback) {
    let promises = [];

    for (let idx of this.preValidators) {
      promises.push(this.validate(this.preValidators[idx], callback));
    }

    return new Promise((resolve, reject) => {
      Promise.all(promises).then((results) => {
        if (results.every((x) => { return !!x; })) {
          resolve(promises.length);
        } else {
          reject('');
        }
      }, reject);
    });
  }

  postValidate(callbacks) {
    let promises = [];
    for (let idx in this.postValidators) {
      if (!Object.prototype.hasOwnProperty.call(this.postValidators, idx)) continue;
      promises.push(this.validate(this.postValidators[idx], callbacks));
    }

    return new Promise((resolve, reject) => {
      Promise.all(promises).then((results) => {
        if (results.every((x) => { return !!x; })) {
          resolve(promises.length);
        } else {
          reject('');
        }
      }, reject);
    });
  }

  walls(i, j) {
    if (0 > i || i > this.h || 0 > j || j > this.w) return 0;
    return this.wallMap[this.w * i + j];
  }

  toggleWall(i, j, orientation) {
    if (j == 1 && orientation === 0 || i == 1 && orientation == 3 ||
        i == this.h && orientation == 1 || j == this.w && orientation == 2) {
      return;
    }

    if (orientation < 0 || orientation >= 4 || 0 > i || i > this.h || 0 > j ||
        j > this.w)
      return;
    // Needed to prevent Karel from traversing walls from one direction, but not
    // from the other.
    this.wallMap[this.w * i + j] ^= (1 << orientation);

    if (orientation === 0 && j > 1) {
      this.wallMap[this.w * i + (j - 1)] ^= (1 << 2);
    } else if (orientation === 1 && i < this.h) {
      this.wallMap[this.w * (i + 1) + j] ^= (1 << 3);
    } else if (orientation === 2 && j < this.w) {
      this.wallMap[this.w * i + (j + 1)] ^= (1 << 0);
    } else if (orientation === 3 && i > 1) {
      this.wallMap[this.w * (i - 1) + j] ^= (1 << 1);
    }

    this.dirty = true;
  }

  addBorderWalls() {
    for (let i = 1; i <= this.h; i++) {
      this.addWall(i, 1, 0);
      this.addWall(i, this.w, 2);
    }

    for (let j = 1; j <= this.w; j++) {
      this.addWall(this.h, j, 1);
      this.addWall(1, j, 3);
    }
  }

  setCellWalls(i, j, wallMask) {
    for (let pos = 0; pos < 4; pos++) {
      if (wallMask & (1 << pos)) this.addWall(i, j, pos);
    }
  }

  addWall(i, j, orientation) {
    if (orientation < 0 || orientation >= 4 || 0 > i || i > this.h || 0 > j ||
        j > this.w)
      return;
    this.wallMap[this.w * i + j] |= (1 << orientation);

    if (orientation === 0 && j > 1)
      this.wallMap[this.w * i + (j - 1)] |= (1 << 2);
    else if (orientation === 1 && i < this.h)
      this.wallMap[this.w * (i + 1) + j] |= (1 << 3);
    else if (orientation === 2 && j < this.w)
      this.wallMap[this.w * i + (j + 1)] |= (1 << 0);
    else if (orientation === 3 && i > 1)
      this.wallMap[this.w * (i - 1) + j] |= (1 << 1);

    this.dirty = true;
  }

  setBuzzers(i, j, count) {
    if (0 > i || i > this.h || 0 > j || j > this.w) return;
    this.map[this.w * i + j] = this.currentMap[this.w * i + j] =
        (count == 0xffff) ? -1 : count;
    this.dirty = true;
  }

  buzzers(i, j) {
    if (0 > i || i > this.h || 0 > j || j > this.w) return 0;
    return this.currentMap[this.w * i + j];
  }

  pickBuzzer(i, j) {
    if (0 > i || i > this.h || 0 > j || j > this.w) return;
    if (this.currentMap[this.w * i + j] != -1) {
      this.currentMap[this.w * i + j]--;
    }
    if (this.bagBuzzers != -1) {
      this.bagBuzzers++;
    }
    this.dirty = true;
  }

  leaveBuzzer(i, j) {
    if (0 > i || i > this.h || 0 > j || j > this.w) return;
    if (this.currentMap[this.w * i + j] != -1) {
      this.currentMap[this.w * i + j]++;
    }
    if (this.bagBuzzers != -1) {
      this.bagBuzzers--;
    }
    this.dirty = true;
  }

  setDumpCell(i, j, dumpState) {
    let dumpPos = -1;

    if (0 > i || i > this.h || 0 > j || j > this.w) return;

    for (dumpPos = 0; dumpPos < this.dumpCells.length; dumpPos++) {
      if (this.dumpCells[dumpPos][0] == i && this.dumpCells[dumpPos][1] == j) {
        break;
      }
    }

    if (dumpPos < this.dumpCells.length) {
      if (dumpState) return;
      this.dumpCells.splice(dumpPos, 0);
    } else {
      if (!dumpState) return;
      this.dumpCells.push([i, j]);
    }

    this.dumps[World.DUMP_WORLD] = this.dumpCells.length !== 0;
  }

  toggleDumpCell(i, j) {
    let dumpPos = 0;

    if (0 > i || i > this.h || 0 > j || j > this.w) return;

    for (; dumpPos < this.dumpCells.length; dumpPos++) {
      if (this.dumpCells[dumpPos][0] == i && this.dumpCells[dumpPos][1] == j) {
        break;
      }
    }

    if (dumpPos < this.dumpCells.length) {
      this.dumpCells.splice(dumpPos, 1);
    } else {
      this.dumpCells.push([i, j]);
    }

    this.dumps[World.DUMP_WORLD] = this.dumpCells.length !== 0;
  }

  getDumpCell(i, j) {
    let dumpPos = -1;

    for (dumpPos = 0; dumpPos < this.dumpCells.length; dumpPos++) {
      if (this.dumpCells[dumpPos][0] == i && this.dumpCells[dumpPos][1] == j) {
        return true;
      }
    }

    return false;
  }

  getDumps(d) {
    return Object.prototype.hasOwnProperty.call(this.dumps, d.toLowerCase()) && this.dumps[d];
  }

  setDumps(d, v) {
    this.dumps[d] = v;
  }

  toggleDumps(d) {
    this.setDumps(d, !this.getDumps(d));
  }

  load(xml) {
    this.clear();

    // It builds the current world by applying the rules to matching xml nodes.
    let rules = {
      mundo: (mundo) => {
        let alto = mundo.getAttribute('alto');
        let ancho = mundo.getAttribute('ancho');

        if (!alto || !ancho) {
          return;
        }
        alto = parseInt(alto, 10);
        ancho = parseInt(ancho, 10);
        if (!alto || !ancho) {
          return;
        }
        this.resize(ancho, alto);
      },

      condiciones: (condiciones) => {
        this.maxInstructions =
            parseInt(condiciones.getAttribute('instruccionesMaximasAEjecutar'),
                     10) ||
            10000000;
        this.maxStackSize =
            parseInt(condiciones.getAttribute('longitudStack'), 10) || 65000;
      },

      comando: (comando) => {
        let name = comando.getAttribute('nombre');
        let val = parseInt(comando.getAttribute('maximoNumeroDeEjecuciones'), 10);

        if (!name || !val) {
          return;
        }

        if (name == 'AVANZA') {
          this.maxMove = val;
        } else if (name == 'GIRA_IZQUIERDA') {
          this.maxTurnLeft = val;
        } else if (name == 'COGE_ZUMBADOR') {
          this.maxPickBuzzer = val;
        } else if (name == 'DEJA_ZUMBADOR') {
          this.maxLeaveBuzzer = val;
        }
      },

      monton: (monton) => {
        let i = parseInt(monton.getAttribute('y'), 10);
        let j = parseInt(monton.getAttribute('x'), 10);
        let zumbadores = monton.getAttribute('zumbadores');
        if (zumbadores == 'INFINITO') {
          zumbadores = -1;
        } else {
          zumbadores = parseInt(zumbadores, 10);
          if (isNaN(zumbadores)) zumbadores = 0;
        }
        this.setBuzzers(i, j, zumbadores);
      },

      pared: (pared) => {
        let i = parseInt(pared.getAttribute('y1'), 10) + 1;
        let j = parseInt(pared.getAttribute('x1'), 10) + 1;

        if (pared.getAttribute('x2')) {
          let j2 = parseInt(pared.getAttribute('x2'), 10) + 1;

          if (j2 > j) {
            this.addWall(i, j, 3);
          } else {
            this.addWall(i, j2, 3);
          }
        } else if (pared.getAttribute('y2')) {
          let i2 = parseInt(pared.getAttribute('y2'), 10) + 1;

          if (i2 > i) {
            this.addWall(i, j, 0);
          } else {
            this.addWall(i2, j, 0);
          }
        }
      },

      despliega: (despliega) => {
        this.dumps[despliega.getAttribute('tipo').toLowerCase()] = true;
      },

      posicionDump: (dump) => {
        this.dumpCells.push([
          parseInt(dump.getAttribute('y'), 10),
          parseInt(dump.getAttribute('x'), 10)
        ]);
      },

      validador: (validador) => {
        let src = null;
        if (validador.getAttribute('src')) {
          throw 'external validator not imported';
        } else {
          src = validador.firstChild.nodeValue;
        }
        if (validador.getAttribute('tipo') == 'post') {
          this.postValidators.push(src);
        } else {
          this.preValidators.push(src);
        }
      },

      programa: (programa) => {
        let xKarel = parseInt(
            programa.getAttribute('xKarel') || programa.getAttribute('xkarel'),
            10);
        let yKarel = parseInt(
            programa.getAttribute('yKarel') || programa.getAttribute('ykarel'),
            10);
        this.di = this.h / 2 - yKarel;
        this.dj = this.w / 2 - xKarel;
        this.rotate(programa.getAttribute('direccionKarel') ||
                    programa.getAttribute('direccionkarel'));
        this.worldName = programa.getAttribute('mundoDeEjecucion') ||
                         programa.getAttribute('mundodeejecucion');
        this.programName = programa.getAttribute('nombre');
        this.move(yKarel, xKarel);
        let bagBuzzers = programa.getAttribute('mochilaKarel') ||
                         programa.getAttribute('mochilakarel') || 0;
        if (bagBuzzers == 'INFINITO') {
          this.setBagBuzzers(-1);
        } else {
          this.setBagBuzzers(parseInt(bagBuzzers));
        }
      }
    };

    let traverse = (node) => {
      let type = node.nodeName;
      if (Object.prototype.hasOwnProperty.call(rules, type)) {
        rules[type](node);
      }

      for (let i = 0; i < node.childNodes.length; i++) {
        if (node.childNodes.item(i).nodeType === node.ELEMENT_NODE) {
          traverse(node.childNodes.item(i));
        }
      }
    }

    traverse(xml);

    this.reset();

    return this;
  }

  serialize(node, name, indentation) {
    let result = '';
    for (let i = 0; i < indentation; i++) {
      result += '\t';
    }

    if (typeof node === 'string' || typeof node === 'number') {
      return result + node;
    }

    if (Array.isArray(node)) {
      result = '';

      for (let i = 0; i < node.length; i++) {
        result += this.serialize(node[i], name, indentation);
      }
    } else {
      let childResult = '';

      for (let p in node) {
        if (Object.prototype.hasOwnProperty.call(node, p)) {
          if (p[0] == '#') {
            continue;
          } else {
            childResult += this.serialize(node[p], p, indentation + 1);
          }
        }
      }

      result += '<' + name;

      if (Object.prototype.hasOwnProperty.call(node, '#attributes')) {
        for (let p in node['#attributes']) {
          if (Object.prototype.hasOwnProperty.call(node['#attributes'], p)) {
            result += ' ' + p + '="' + node['#attributes'][p] + '"';
          }
        }
      }

      if (Object.prototype.hasOwnProperty.call(node, '#text')) {
        result += '>' + node['#text'] + '</' + name + '>\n';
      } else if (childResult == '') {
        result += '/>\n';
      } else {
        result += '>\n';
        result += childResult;
        for (let i = 0; i < indentation; i++) {
          result += '\t';
        }
        result += '</' + name + '>\n';
      }
    }

    return result;
  }

  save() {
    let result = {
      condiciones: {
        '#attributes': {
          instruccionesMaximasAEjecutar: this.maxInstructions,
          longitudStack: this.maxStackSize
        }
      },
      mundos: {
        mundo: {
          '#attributes': {nombre: this.worldName, ancho: this.w, alto: this.h},
          monton: [],
          pared: [],
          posicionDump: []
        }
      },
      programas: {
        '#attributes': {
          tipoEjecucion: 'CONTINUA',
          intruccionesCambioContexto: 1,
          milisegundosParaPasoAutomatico: 0
        },
        programa: {
          '#attributes': {
            nombre: this.programName,
            ruta: '{$2$}',
            mundoDeEjecucion: this.worldName,
            xKarel: this.j,
            yKarel: this.i,
            direccionKarel: ['OESTE', 'NORTE', 'ESTE', 'SUR'][this.orientation],
            mochilaKarel: this.bagBuzzers == -1 ? 'INFINITO' : this.bagBuzzers
          },
          despliega: []
        }
      }
    };

    for (let i = 1; i <= this.h; i++) {
      for (let j = 1; j <= this.w; j++) {
        let buzzers = this.buzzers(i, j);
        if (buzzers !== 0) {
          result.mundos.mundo.monton.push({
            '#attributes':
                {x: j, y: i, zumbadores: buzzers == -1 ? 'INFINITO' : buzzers}
          });
        }
      }
    }

    for (let i = 1; i <= this.h; i++) {
      for (let j = 1; j <= this.w; j++) {
        let walls = this.walls(i, j);
        for (let k = 2; k < 8; k <<= 1) {
          if (i == this.h && k == 2) continue;
          if (j == this.w && k == 4) continue;

          if ((walls & k) == k) {
            if (k == 2) {
              result.mundos.mundo.pared.push(
                  {'#attributes': {x1: j - 1, y1: i, x2: j}});
            } else if (k == 4) {
              result.mundos.mundo.pared.push(
                  {'#attributes': {x1: j, y1: i - 1, y2: i}});
            }
          }
        }
      }
    }

    for (let i = 0; i < this.dumpCells.length; i++) {
      result.mundos.mundo.posicionDump.push(
          {'#attributes': {x: this.dumpCells[i][1], y: this.dumpCells[i][0]}});
    }

    for (let p in this.dumps) {
      if (Object.prototype.hasOwnProperty.call(this.dumps, p) && this.dumps[p]) {
        result.programas.programa.despliega.push(
            {'#attributes': {tipo: p.toUpperCase()}});
      }
    }

    if (this.preValidators || this.postValidators) {
      result.validadores = [];
      for (let i = 0; i < this.preValidators.length; i++) {
        result.validadores.push({
          validador: {
            '#attributes': {tipo: 'pre'},
            '#text': '<![CDATA[' + this.preValidators[i] + ']]>'
          }
        });
      }
      for (let i = 0; i < this.postValidators.length; i++) {
        result.validadores.push({
          validador: {
            '#attributes': {tipo: 'post'},
            '#text': '<![CDATA[' + this.postValidators[i] + ']]>'
          }
        });
      }
    }

    return this.serialize(result, 'ejecucion', 0);
  }

  output() {
    let result = {};

    if (this.dumps[World.DUMP_WORLD] || this.dumps[World.DUMP_ALL_BUZZERS]) {
      result.mundos = {
        mundo: {'#attributes': {nombre: this.worldName}, linea: []}
      };

      let dumpCells = {};
      for (let i = 0; i < this.dumpCells.length; i++) {
        if (!Object.prototype.hasOwnProperty.call(dumpCells, this.dumpCells[i][0])) {
          dumpCells[this.dumpCells[i][0]] = {};
        }
        dumpCells[this.dumpCells[i][0]][this.dumpCells[i][1]] = true;
      }

      for (let i = this.h; i > 0; i--) {
        let printCoordinate = true;
        let line = '';

        for (let j = 1; j <= this.w; j++) {
          if ((dumpCells[i] && dumpCells[i][j]) ||
              this.dumps[World.DUMP_ALL_BUZZERS]) {
            if (this.buzzers(i, j) !== 0) {
              if (printCoordinate) {
                line += '(' + j + ') ';
              }
              // TODO: Este es un bug en karel.exe.
              line += (this.buzzers(i, j) & 0xffff) + ' ';
            }
            printCoordinate = this.buzzers(i, j) == 0;
          }
        }

        if (line !== '') {
          result.mundos.mundo.linea.push({
            '#attributes': {fila: i, compresionDeCeros: 'true'},
            '#text': line
          });
        }
      }
    }

    result.programas = {programa: {'#attributes': {nombre: this.programName}}};

    result.programas.programa['#attributes'].resultadoEjecucion =
        this.errorMap(this.runtime.state.error);

    if (this.dumps[World.DUMP_POSITION]) {
      result.programas.programa.karel = {'#attributes': {x: this.j, y: this.i}};
    }

    if (this.dumps[World.DUMP_ORIENTATION]) {
      result.programas.programa.karel =
          result.programas.programa.karel || {'#attributes': {}};
      result.programas.programa.karel['#attributes'].direccion =
          ['OESTE', 'NORTE', 'ESTE', 'SUR'][this.orientation];
    }

    if (this.dumps[World.DUMP_BAG]) {
      result.programas.programa.karel =
          result.programas.programa.karel || {'#attributes': {}};
      result.programas.programa.karel['#attributes'].mochila =
          this.bagBuzzers == -1 ? 'INFINITO' : this.bagBuzzers;
    }

    if (this.dumps[World.DUMP_MOVE]) {
      result.programas.programa.instrucciones =
          result.programas.programa.instrucciones || {'#attributes': {}};
      result.programas.programa.instrucciones['#attributes'].avanza =
          this.runtime.state.moveCount;
    }

    if (this.dumps[World.DUMP_LEFT]) {
      result.programas.programa.instrucciones =
          result.programas.programa.instrucciones || {'#attributes': {}};
      result.programas.programa.instrucciones['#attributes'].gira_izquierda =
          this.runtime.state.turnLeftCount;
    }

    if (this.dumps[World.DUMP_PICK_BUZZER]) {
      result.programas.programa.instrucciones =
          result.programas.programa.instrucciones || {'#attributes': {}};
      result.programas.programa.instrucciones['#attributes'].coge_zumbador =
          this.runtime.state.pickBuzzerCount;
    }

    if (this.dumps[World.DUMP_LEAVE_BUZZER]) {
      result.programas.programa.instrucciones =
          result.programas.programa.instrucciones || {'#attributes': {}};
      result.programas.programa.instrucciones['#attributes'].deja_zumbador =
          this.runtime.state.leaveBuzzerCount;
    }

    return this.serialize(result, 'resultados', 0);
  }

  errorMap(s) {
    if (!s) return 'FIN PROGRAMA';
    if (Object.prototype.hasOwnProperty.call(World.ERROR_MAPPING, s)) {
      return World.ERROR_MAPPING[s];
    } else {
      return s;
    }
  }

  move(i, j) {
    this.i = this.start_i = i;
    this.j = this.start_j = j;
    this.dirty = true;
  }

  rotate(orientation) {
    let orientations = ['OESTE', 'NORTE', 'ESTE', 'SUR'];
    if (!orientation) {
      orientation = orientations[(this.orientation + 1) % 4];
    }
    this.orientation = this.startOrientation =
        Math.max(0, orientations.indexOf(orientation));
    this.dirty = true;
  }

  setBagBuzzers(buzzers) {
    if (isNaN(buzzers)) buzzers = 0;
    this.bagBuzzers = this.startBagBuzzers = (buzzers == 0xffff ? -1 : buzzers);
    this.dirty = true;
  }

  reset() {
    this.orientation = this.startOrientation;
    this.move(this.start_i, this.start_j);
    this.bagBuzzers = this.startBagBuzzers;

    for (let i = 0; i < this.currentMap.length; i++) {
      this.currentMap[i] = this.map[i];
    }

    this.runtime.reset();

    this.dirty = true;
  }

  import(mdo, kec) {
    if (mdo.length < 20 || kec.length < 30) {
      throw new Error('Invalid file format');
    }

    if (mdo[0] != 0x414b || mdo[1] != 0x4552 || mdo[2] != 0x204c ||
        mdo[3] != 0x4d4f || mdo[4] != 0x2e49) {
      throw new Error('Invalid magic number');
    }

    let width = mdo[6];
    let height = mdo[7];
    this.init(width, height);
    this.setBagBuzzers(mdo[8]);
    this.move(mdo[10], mdo[9]);
    this.orientation = this.startOrientation = mdo[11] % 4;
    let wallcount = mdo[12];
    let heapcount = mdo[13];

    if (kec[0]) {
      this.maxInstructions = kec[1];
    }
    if (kec[3]) {
      this.maxMove = kec[4];
    }
    if (kec[6]) {
      this.maxTurnLeft = kec[7];
    }
    if (kec[9]) {
      this.maxPickBuzzer = kec[10];
    }
    if (kec[12]) {
      this.maxLeaveBuzzer = kec[13];
    }
    if (kec[15]) {
      this.maxKarelBuzzers = kec[16];
    }
    if (kec[18]) {
      this.maxBuzzers = kec[19];
    }
    if (kec[21]) {
      this.setDumps(World.DUMP_POSITION, true);
    }
    if (kec[24]) {
      this.setDumps(World.DUMP_ORIENTATION, true);
    }
    let dumpcount = kec[27] ? kec[28] : 0;
    if (dumpcount) {
      this.setDumps(World.DUMP_WORLD, true);
    }

    let decodeWalls = (tx, ty, tmask) => {
      for (let i = 0; i < 4; i++) {
        if (tmask & (1 << i)) {
          this.addWall(ty, tx, (i + 1) % 4);
        }
      }
    }

    for (let i = 15; i < 15 + 3 * wallcount; i += 3) {
      decodeWalls(mdo[i], mdo[i + 1], mdo[i + 2]);
    }

    for (let i = 15 + 3 * wallcount; i < 15 + 3 * (wallcount + heapcount);
         i += 3) {
      this.setBuzzers(mdo[i + 1], mdo[i], mdo[i + 2]);
    }

    for (let i = 30; i < 30 + 3 * dumpcount; i += 3) {
      this.setDumpCell(kec[i + 1], kec[i], true);
    }
  }
}

World.DUMP_WORLD = 'mundo';
World.DUMP_POSITION = 'posicion';
World.DUMP_ORIENTATION = 'orientacion';
World.DUMP_INSTRUCTIONS = 'instrucciones';
World.DUMP_ALL_BUZZERS = 'universo';
World.DUMP_BAG = 'mochila';
World.DUMP_MOVE = 'avanza';
World.DUMP_LEFT = 'gira_izquierda';
World.DUMP_PICK_BUZZER = 'coge_zumbador';
World.DUMP_LEAVE_BUZZER = 'deja_zumbador';

World.ERROR_MAPPING = {
  BAGUNDERFLOW: 'ZUMBADOR INVALIDO',
  WALL: 'MOVIMIENTO INVALIDO',
  WORLDUNDERFLOW: 'ZUMBADOR INVALIDO',
  STACK: 'STACK OVERFLOW',
  INSTRUCTION: 'LIMITE DE INSTRUCCIONES'
};

export default World;
