<template>
  <div>
    <canvas
      ref="canvas"
      :width="width"
      :height="height"
      @contextmenu.prevent="handleContextmenu"
      @click="handleClick"
      @mousemove="handleMousemove"
    >
    </canvas>
    <ContextMenu
      ref="contextmenu"
      :left="contextmenuX"
      :top="contextmenuY"
      @set-karel-north="setKarelPosition('NORTE')"
      @set-karel-south="setKarelPosition('SUR')"
      @set-karel-east="setKarelPosition('ESTE')"
      @set-karel-west="setKarelPosition('OESTE')"
      @set-zero-beepers="setNBeepers(0)"
      @set-inf-beepers="setNBeepers(-1)"
      @set-n-beepers="setNBeepers($event)"
    />
  </div>
</template>

<script>
import WorldRender, { CellType } from '../lib/WorldRender.js'
import World from '../lib/World.js';
import Vue from 'vue';
import { RunningState } from '../lib/constants.js';
import EditableMixin from '../mixins/EditableMixin.js';
import karelpascal from '../parser/karelpascal.js';
import karelruby from '../parser/karelruby.js';
import kareljava from '../parser/kareljava.js';
import ContextMenu from '../controls/ContextMenu.vue';

export default {
  name: 'WorldCanvas',

  mixins: [EditableMixin],

  props: {
    delay: Number,
    width: Number,
    height: Number,
    code: String,
    language: String,
    state: Number,
    trackKarel: Boolean,
  },

  components: {
    ContextMenu,
  },

  data() {
    return {
      context: null,
      renderer: null,
      worldXml: `<ejecucion>
    <condiciones instruccionesMaximasAEjecutar="10000000" longitudStack="65000" />
    <mundos>
      <mundo nombre="mundo_0" ancho="100" alto="100">
      </mundo>
    </mundos>
    <programas tipoEjecucion="CONTINUA" intruccionesCambioContexto="1" milisegundosParaPasoAutomatico="0">
      <programa nombre="p1" ruta="{$2$}" mundoDeEjecucion="mundo_0" xKarel="1" yKarel="1" direccionKarel="NORTE" mochilaKarel="0" >
      </programa>
    </programas>
  </ejecucion>`,
      world: null,
      currentCell: null,
      contextmenuX: 0,
      contextmenuY: 0,
      ctx_row: 0,
      ctx_col: 0,
    };
  },

  mounted() {
  },

  methods: {
    getParser() {
      switch (this.language) {
        case 'pascal':
          return {parser: new karelpascal.Parser(), name: 'pascal'};
        case 'java':
          return {parser: new kareljava.Parser(), name: 'java'};
        case 'ruby':
          return {parser: new karelruby.Parser(), name: 'ruby'};
        default:
          return {parser: new karelruby.Parser(), name: 'ruby'};
      }
    },

    parseError(str, hash) {
      if (hash.recoverable) {
        this.trace(str);
      } else {
        let e = new Error(str);
        for (let n in hash) {
          if (Object.prototype.hasOwnProperty.call(hash, n)) {
            e[n] = hash[n];
          }
        }

        let line = this.code.split('\n')[e.line];
        let i = line.indexOf(e.text, hash.loc ? hash.loc.first_column : 0);
        if (i == -1) {
          i = line.indexOf(e.text);
        }
        if (i != -1) {
          e.loc = {
            first_line: e.line,
            last_line: e.line,
            first_column: i,
            last_column: i + e.text.length
          };
        } else {
          e.loc = {
            first_line: e.line,
            last_line: e.line + 1,
            first_column: 0,
            last_column: 0
          };
        }
        throw e;
      }
    },

    getSyntax() {
      let parser = this.getParser();
      parser.parser.yy.parseError = this.parseError;
      return parser;
    },

    compile() {
      let syntax = this.getSyntax();

      // TODO this raises an exception, check
      return syntax.parser.parse(this.code);
    },

    highlightCurrentLine() {
      // TODO implement
    },

    renderLoop() {
      if (this.state == RunningState.RUNNING) {
        setTimeout(this.renderLoop, this.delay);
      }

      this.step();
    },

    step() {
      // Avanza un paso en la ejecución del código
      let world = this.getWorld();

      world.runtime.step();
      this.$emit('update:beepers', world.bagBuzzers);

      this.highlightCurrentLine();

      if (world.dirty) {
        world.dirty = false;
        this.repaint();
      }

      if (!world.runtime.state.running) {
        this.$emit('update:state', RunningState.FINISHED);
      }
    },

    requestPlay() {
      if (this.state == RunningState.CLEAN || this.state == RunningState.FINISHED) {
        // TODO handle code that does not compile here
        let compiled = this.compile();
        let world = this.getWorld();

        world.reset();
        world.runtime.load(compiled);

        world.preValidate(() => {}).then(() => {
          this.$emit('update:state', RunningState.RUNNING);
          setTimeout(this.renderLoop, this.delay);
        });
      } else if (this.state == RunningState.PAUSED) {
        this.$emit('update:state', RunningState.RUNNING);
        setTimeout(this.renderLoop, this.delay);
      }
    },

    requestPause() {
      this.$emit('update:state', RunningState.PAUSED);
    },

    requestStep() {
      this.step();
    },

    requestReset() {
      // TODO clear line hightlighting in the editor

      // reset the world's state
      let world = this.getWorld();
      world.load(new DOMParser().parseFromString(this.worldXml, 'text/xml'));
      this.$emit('update:beepers', world.bagBuzzers);
      this.repaint();

      // tell parent components about change of state
      this.$emit('update:state', RunningState.CLEAN);
    },

    requestSetBeepers(beepers) {
      let world = this.getWorld();

      world.setBagBuzzers(beepers);
      this.worldXml = world.save();
      this.$emit('update:beepers', world.bagBuzzers);
    },

    getContext() {
      if (this.context === null) {
        let canvas = this.$refs.canvas;

        this.context = canvas.getContext('2d');
      }

      return this.context;
    },

    getRenderer() {
      if (this.renderer === null) {
        this.renderer = new WorldRender(this.getContext(), 100, 100);
      }

      return this.renderer;
    },

    getWorld() {
      if (this.world === null) {
        this.world = (new World()).load(new DOMParser().parseFromString(this.worldXml, 'text/xml'));
      }

      return this.world;
    },

    repaint() {
      this.getRenderer().paint(this.getWorld(), this.width, this.height, {
        editable: this.editable,
        track_karel: this.trackKarel,
      });
    },

    handleContextmenu(event) {
      if (!this.editable) {
        return;
      }

      let canvasWidth = this.$refs.canvas.width;
      let canvasHeight = this.$refs.canvas.height;
      let cMenuWidth = this.$refs.contextmenu.width;
      let cMenuHeight = this.$refs.contextmenu.height;
      let eventX = event.layerX;
      let eventY = event.layerY;

      if (eventX + cMenuWidth >= canvasWidth) {
        eventX = eventX - cMenuWidth;
      }

      if (eventY + cMenuHeight >= canvasHeight) {
        eventY = eventY - cMenuHeight;
      }

      this.contextmenuX = eventX;
      this.contextmenuY = eventY;

      let renderer = this.getRenderer();

      this.ctx_col = Math.floor(event.offsetX / renderer.tamano_celda) + renderer.primera_columna - 1;
      this.ctx_row = Math.floor((this.$refs.canvas.height - event.offsetY) / renderer.tamano_celda) + renderer.primera_fila - 1;

      this.$refs.contextmenu.show();
    },

    setNBeepers(n) {
      let world = this.getWorld();

      world.setBuzzers(this.ctx_row, this.ctx_col, n);
      this.repaint();
      this.worldXml = world.save();
    },

    setKarelPosition(orientation) {
      let world = this.getWorld();

      world.move(this.ctx_row, this.ctx_col);
      world.rotate(orientation);
      this.repaint();
      this.worldXml = world.save();
    },

    handleClick(event) {
      this.$refs.contextmenu.hide();
      // Maneja los clicks en el mundo
      let renderer = this.getRenderer();
      let world = this.getWorld();

      if (this.editable && this.currentCell) {
        if (this.currentCell.kind == CellType.Corner) {
          if (renderer.polygon) {
            let result = renderer.polygonFinish(this.currentCell.row,
                                               this.currentCell.column);
            if (result) {
              for (let i = result.start_row; i < result.finish_row; i++) {
                world.toggleWall(i, result.start_column, 0);  // oeste
              }
              for (let i = result.start_column; i < result.finish_column;
                   i++) {
                world.toggleWall(result.start_row, i, 3);  // sur
              }
            }
          }
          renderer.polygonStart(this.currentCell.row, this.currentCell.column);
        } else {
          renderer.polygonClear();

          if (this.currentCell.kind == CellType.WestWall) {
            world.toggleWall(this.currentCell.row, this.currentCell.column,
                             0);  // oeste
          } else if (this.currentCell.kind == CellType.SouthWall) {
            world.toggleWall(this.currentCell.row, this.currentCell.column,
                             3);  // sur
          } else if (this.currentCell.kind == CellType.Beeper) {
            if (event.ctrlKey) {
              world.toggleDumpCell(this.currentCell.row, this.currentCell.column);
            } else {
              let zumbadores =
                  world.buzzers(this.currentCell.row, this.currentCell.column);
              if (zumbadores >= 0 && !event.shiftKey)
                world.setBuzzers(this.currentCell.row, this.currentCell.column,
                                 zumbadores + 1);
              else if (zumbadores > 0 && event.shiftKey)
                world.setBuzzers(this.currentCell.row, this.currentCell.column,
                                 zumbadores - 1);
            }
          }
        }

        this.worldXml = world.save();
      }

      // Volvemos a pintar el canvas
      this.repaint();
    },

    handleMousemove(event) {
      let canvas = this.$refs.canvas;

      let x = event.offsetX || (event.clientX + document.body.scrollLeft +
                                document.documentElement.scrollLeft -
                                canvas.offsetLeft);
      let y = event.offsetY || (event.clientY + document.body.scrollTop +
                                document.documentElement.scrollTop -
                                canvas.offsetTop);

      let renderer = this.getRenderer();
      let cellInfo = renderer.calculateCell(x, canvas.height - y);

      let changed = !((this.currentCell) && cellInfo.row == this.currentCell.row &&
                  cellInfo.column == this.currentCell.column &&
                  cellInfo.kind == this.currentCell.kind);
      this.currentCell = cellInfo;

      if (this.editable && changed) {
        this.repaint();

        if (cellInfo.kind == CellType.Corner) {
          if (renderer.polygon) {
            renderer.polygonUpdate(cellInfo.row, cellInfo.column);
            this.repaint();
          }
          renderer.hoverCorner(cellInfo.row, cellInfo.column, canvas.width,
                              canvas.height);
        } else {
          if (cellInfo.kind == CellType.WestWall) {
            renderer.hoverWall(cellInfo.row, cellInfo.column, 0,
                              canvas.width, canvas.height);  // oeste
          } else if (cellInfo.kind == CellType.SouthWall) {
            renderer.hoverWall(cellInfo.row, cellInfo.column, 3,
                              canvas.width, canvas.height);  // sur
          } else if (cellInfo.kind == CellType.Beeper) {
            renderer.hoverBuzzer(cellInfo.row, cellInfo.column,
                                canvas.width, canvas.height);
          }
        }
      }
    },
  },

  watch: {
    width() { Vue.nextTick(() => this.repaint()); },
    height() { Vue.nextTick(() => this.repaint()); },
    editable() { Vue.nextTick(() => this.repaint()); },
  },
}
</script>

<style scoped>
</style>
