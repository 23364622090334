<template>
  <div id="code-panel">
    <div class="action-bar">
      <button class="btn action" :disabled="!editable"><fa icon="plus" /> nuevo</button>
      <button class="btn action" :disabled="!editable"><fa icon="folder-open" /> abrir</button>
      <button class="btn action" :disabled="!editable"><fa icon="save" /> guardar</button>
      <button class="btn action" :disabled="!editable"><fa icon="palette" /> tema</button>
      <button class="btn action" :disabled="!editable"><fa icon="code" /> sintaxis</button>
    </div>
    <codemirror ref="cm" :value="value" :options="cmOptions" @input="$emit('input', $event)" :class="{ readOnly: cmOptions.readOnly }" />
  </div>
</template>

<script>
import CodeMirror from 'codemirror';
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import pascalSyntax from '../syntax/pascal.js'

export default {
  name: 'CodePanel',

  props: {
    value: String,
    editable: Boolean,
  },

  computed: {
    cmOptions() {
      return {
        tabSize: 4,
        mode: 'karelpascal',
        theme: 'karel',
        readOnly: !this.editable,
        lineNumbers: true,
        line: true,
      };
    },
  },

  components: {
    codemirror,
  },

  beforeMount() {
    CodeMirror.defineMode('karelpascal', pascalSyntax);
    CodeMirror.defineMIME('text/x-karelpascal', 'karelpascal');
  },
}
</script>

<style scoped>
#code-panel {
  display: flex;
  flex-direction: column;
}
</style>
