<template>
  <button class="btn btn-primary action" @click="handleClick" title="Regresar al estado inicial">
    <fa icon="stop" />
  </button>
</template>

<script>
export default {
  name: 'StopBtn',

  methods: {
    handleClick() {
      this.$emit('click');
    },
  }
}
</script>

<style scoped>
</style>
