<template>
  <div class="action btn-group" title="Mochila de karel">
    <button class="btn left">
      <fa icon="suitcase" />
    </button>
    <input
      :readonly="!editable"
      :value="beeperCount"
      @keyup="change"
      class="main"
      type="text"
    />
    <button class="btn right" :disabled="!editable" @click="setInfty">
      <fa icon="infinity" />
    </button>
  </div>
</template>

<script>
export default {
  name: 'BagBtn',

  props: {
    beepers: Number,
    editable: Boolean,
  },

  computed: {
    beeperCount() {
      if (this.beepers >= 0) {
        return this.beepers;
      } else {
        return 'infinitos';
      }
    },
  },

  methods: {
    change(event) {
      if (!this.editable) {
        return;
      }

      let proposedValue = event.target.value;

      let processedValue = proposedValue.split('').filter(c => c.match(/[0-9]/)).join('');

      if (processedValue.length > 0) {
        this.$emit('update:beepers', parseInt(processedValue));
      } else {
        this.$emit('update:beepers', 0);
      }
    },

    setInfty() {
      if (!this.editable) {
        return;
      }

      this.$emit('update:beepers', -1);
    },
  },
}
</script>

<style scoped>
input {
  width: 50px;
  border: 0;
  padding: 0;
  text-align: center;
}
</style>
