<template>
  <button class="btn action" @click="handleClick" title="Avanzar un paso a la vez">
    <fa icon="step-forward" />
  </button>
</template>

<script>
export default {
  name: 'StepBtn',

  methods: {
    handleClick() {
      this.$emit('click');
    },
  }
}
</script>

<style scoped>
</style>
