<template>
  <span class="btn action" title="Retraso en milisegundos entre instrucciones">
    <fa icon="stopwatch" />
    <input
      type="range"
      :value="value"
      :min="min"
      :max="max"
      :step="step"
      @input="$emit('input', parseInt($event.target.value))"
    />
    {{ value }} ms
  </span>
</template>

<script>
export default {
  name: 'SpeedBtn',

  props: {
    value: Number,
    min: {
      type: Number,
      default: 2,
    },
    max: {
      type: Number,
      default: 100,
    },
    step: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style>
input {
  width: 60px;
}
</style>
