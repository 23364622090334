<template>
  <button class="btn btn-primary action" @click="togglePlay" :title="text">
    <fa :icon="icon" />
  </button>
</template>

<script>
export default {
  name: 'PlayBtn',

  props: {
    playing: Boolean,
  },

  computed: {
    icon() {
      return this.playing ? 'pause': 'play';
    },
    text() {
      return this.playing ? 'detener': 'iniciar';
    },
  },

  methods: {
    togglePlay() {
      this.$emit('update:playing', !this.playing);
    },
  }
}
</script>

<style scoped>
</style>
