/* Cosas para dibujar el canvas del mundo */

export const CellType = {
  Beeper: 0,
  Corner: 1,
  WestWall: 2,
  NorthWall: 3,
  EastWall: 4,
  SouthWall: 5,
  Outbounds: 6,
};

const KarelOrientation = {
  West: 0,
  North: 1,
  East: 2,
  South: 3,
};

export default class WorldRender {
  constructor(context, worldWidth, worldHeight) {
    this.should_draw = true;
    this.context = context;

    this.primera_fila = 1;
    this.primera_columna = 1;

    this.num_filas = 1;
    this.num_columnas = 1;

    this.tamano_celda = 30;
    this.margin = 7.5;

    this.worldHeight = worldHeight;
    this.worldWidth = worldWidth;

    this.polygon = false;
    this.polygon_begin = undefined;
    this.polygon_end = undefined;
  }

  paint(world, mundo_ancho, mundo_alto, options) {
    options = options || {};

    function dibuja_karel(context, world, origen) {
      context.fillStyle = '#007EBE';
      context.beginPath();

      if (world.orientation == KarelOrientation.West) {
        context.moveTo(origen.x, origen.y + 15);
        context.lineTo(origen.x + 14, origen.y + 1);
        context.lineTo(origen.x + 14, origen.y + 8);
        context.lineTo(origen.x + 28, origen.y + 8);
        context.lineTo(origen.x + 28, origen.y + 22);
        context.lineTo(origen.x + 14, origen.y + 22);
        context.lineTo(origen.x + 14, origen.y + 29);
      } else if (world.orientation == KarelOrientation.North) {
        context.moveTo(origen.x + 1, origen.y + 14);
        context.lineTo(origen.x + 15, origen.y);
        context.lineTo(origen.x + 29, origen.y + 14);
        context.lineTo(origen.x + 22, origen.y + 14);
        context.lineTo(origen.x + 22, origen.y + 28);
        context.lineTo(origen.x + 8, origen.y + 28);
        context.lineTo(origen.x + 8, origen.y + 14);
      } else if (world.orientation == KarelOrientation.East) {
        context.moveTo(origen.x + 2, origen.y + 8);
        context.lineTo(origen.x + 17, origen.y + 8);
        context.lineTo(origen.x + 17, origen.y + 1);
        context.lineTo(origen.x + 30, origen.y + 15);
        context.lineTo(origen.x + 17, origen.y + 29);
        context.lineTo(origen.x + 17, origen.y + 22);
        context.lineTo(origen.x + 2, origen.y + 22);
      } else if (world.orientation == KarelOrientation.South) {
        context.moveTo(origen.x + 8, origen.y + 2);
        context.lineTo(origen.x + 22, origen.y + 2);
        context.lineTo(origen.x + 22, origen.y + 17);
        context.lineTo(origen.x + 29, origen.y + 17);
        context.lineTo(origen.x + 15, origen.y + 30);
        context.lineTo(origen.x + 1, origen.y + 17);
        context.lineTo(origen.x + 8, origen.y + 17);
      }
      context.closePath();
      context.fill();
    }

    this.context.clearRect(0, 0, mundo_ancho, mundo_alto);
    this.context.fillStyle = '#e6e6e6';
    this.context.fillRect(0, 0, mundo_ancho, mundo_alto);

    let tamanio_lienzo = {x: (mundo_ancho - 30), y: (mundo_alto - 30)};

    this.context.fillStyle = options.editable ? '#FFFFFF' : '#FAFAFA';
    this.context.fillRect(30, 0, tamanio_lienzo.x, tamanio_lienzo.y);

    // Coordenada para dibujar la primera casilla
    let origen = {x: 30, y: mundo_alto - 60};

    this.num_columnas =
        (tamanio_lienzo.x / 30 + Math.ceil((tamanio_lienzo.x % 30) / 30.)) * 1;
    this.num_filas =
        (tamanio_lienzo.y / 30 + Math.ceil((tamanio_lienzo.y % 30) / 30.)) * 1;

    if (options.track_karel) {
      // Rastrea la ubicación de karel y lo forza a aparecer
      if (world.i < this.primera_fila) {
        this.primera_fila = Math.floor(world.i);
      } else if (world.i > ((this.primera_fila + this.num_filas) - 2)) {
        this.primera_fila = Math.floor(world.i - this.num_filas) + 3;
      }
      if (world.j < this.primera_columna) {
        this.primera_columna = Math.floor(world.j);
      } else if (world.j > ((this.primera_columna + this.num_columnas) - 2)) {
        this.primera_columna = Math.floor(world.j - this.num_columnas) + 3;
      }
    }

    // Cuadrados de las esquinas
    for (let i = 0; i < this.num_filas; i++) {
      for (let j = 0; j < this.num_columnas; j++) {
        let x = origen.x + 30 * j;
        let y = origen.y - 30 * i;

        this.context.fillStyle = '#818181';
        this.context.fillRect(x - 2, y + 26, 6, 6);
      }
    }

    // Dibujar las cosas que pertenecen al mundo por cada casilla
    let num_fila = 1;     // Posicion relativa a la pantalla
    let num_columna = 1;  // Posicion relativa a la pantalla

    for (let fila = this.primera_fila;
         fila < (this.primera_fila + this.num_filas); fila++) {
      num_columna = 1;
      for (let columna = this.primera_columna;
           columna < this.primera_columna + this.num_columnas; columna++) {
        // Si esa casilla se debe imprimir
        if (options.editable) {
          this.context.fillStyle = '#eee';
          if (world.getDumpCell(fila, columna)) {
            this.context.fillRect(origen.x + (num_columna - 1) * 30 + 4,
                             origen.y - (num_fila - 1) * 30 + 2, 24, 24);
          }
        }

        // Dibujar a karel
        if (world.i === fila && world.j === columna) {
          let referencia = {
            x: origen.x + (num_columna - 1) * 30,
            y: origen.y - (num_fila - 1) * 30,
          };

          dibuja_karel(this.context, world, referencia);
        }

        // Paredes
        this.context.fillStyle = '#191919';
        let paredes = world.walls(fila, columna);
        if ((paredes & 0x1) != 0) {  // oeste
          this.context.fillRect(origen.x + (num_columna - 1) * 30 - 1,
                           origen.y - (num_fila - 1) * 30, 4, 30);
        }
        if ((paredes & 0x2) != 0) {  // norte
          this.context.fillRect(origen.x + (num_columna - 1) * 30 + 1,
                           origen.y - (num_fila - 1) * 30 + 27 - 30, 30, 4);
        }
        if ((paredes & 0x4) != 0) {  // este
          this.context.fillRect(origen.x + (num_columna - 1) * 30 - 1 + 30,
                           origen.y - (num_fila - 1) * 30, 4, 30);
        }
        if ((paredes & 0x8) != 0) {  // oeste
          this.context.fillRect(origen.x + (num_columna - 1) * 30 + 1,
                           origen.y - (num_fila - 1) * 30 + 27, 30, 4);
        }

        // Zumbadores
        let zumbadores = world.buzzers(fila, columna);
        if (zumbadores == -1 || zumbadores > 0) {
          this.context.fillStyle = options.editable ? '#00FF00' : '#E0E0E0';
          if (zumbadores == -1) {
            this.context.fillRect(origen.x + (num_columna - 1) * 30 + 8,
                             origen.y - (num_fila - 1) * 30 + 8, 16, 12);

            this.context.font = '25px monospace';
            this.context.fillStyle = '#000000';
            this.context.fillText('∞', origen.x + (num_columna - 1) * 30 + 9,
                             origen.y - (num_fila - 1) * 30 + 23);
          } else if (zumbadores < 10) {
            this.context.fillRect(origen.x + (num_columna - 1) * 30 + 9,
                             origen.y - (num_fila - 1) * 30 + 8, 12, 14);

            this.context.font = '12px monospace';
            this.context.fillStyle = '#000000';
            this.context.fillText(String(zumbadores),
                             origen.x + (num_columna - 1) * 30 + 11,
                             origen.y - (num_fila - 1) * 30 + 20);
          } else {
            this.context.fillRect(origen.x + (num_columna - 1) * 30 + 7,
                             origen.y - (num_fila - 1) * 30 + 8, 16, 14);

            this.context.font = '12px monospace';
            this.context.fillStyle = '#000000';
            this.context.fillText(String(zumbadores),
                             origen.x + (num_columna - 1) * 30 + 8,
                             origen.y - (num_fila - 1) * 30 + 20);
          }
        }
        num_columna++;
      }
      num_fila++;
    }
    this.should_draw = !this.should_draw;
    // Numeros de fila
    let a = 1;
    for (let i = this.primera_fila; i < this.primera_fila + this.num_filas; i++) {
      this.context.font = '14px monospace';
      this.context.fillStyle = '#000000';
      this.context.fillText('' + i, 10, mundo_alto - (10 + a * 30));
      a++;
    }

    // Numeros de colummna
    a = 1;
    for (let i = this.primera_columna; i < this.primera_columna + this.num_columnas; i++) {
      this.context.font = '14px monospace';
      this.context.fillStyle = '#000000';
      this.context.fillText('' + i, 10 + 30 * a, mundo_alto - 10);
      a++;
    }

    if (this.polygon) {
      this.context.fillStyle = '#ff0000';
      let from_x =
          origen.x + (this.polygon_begin[1] - this.primera_columna) * 30;
      let from_y = origen.y - (this.polygon_begin[0] - this.primera_fila) * 30;
      let width = 4;
      let height = 4;
      // corner marker
      this.context.fillRect(from_x - 2, from_y + 26, 6, 6);

      if (this.polygon_end) {
        this.context.fillStyle = '#007EBE';
        if (this.polygon_begin[0] == this.polygon_end[0]) {
          width = 30 * (this.polygon_end[1] - this.polygon_begin[1]);
        } else {
          height = 30 * (this.polygon_begin[0] - this.polygon_end[0]);
        }
        this.context.fillRect(from_x - 1, from_y + 27, width, height);
      }
    }
  }

  polygonStart(fila, columna) {
    this.polygon = !((this.polygon_begin) && (this.polygon_begin[0] == fila) &&
                     (this.polygon_begin[1] == columna));
    if (this.polygon) {
      this.polygon_begin = [fila, columna];
    } else {
      this.polygon_begin = undefined;
    }
  }

  polygonUpdate(fila, columna) {
    // debe compartir alguna coordenada pero no las dos
    if (this.polygon_begin[0] == fila ^ this.polygon_begin[1] == columna) {
      this.polygon_end = [fila, columna];
    } else {
      this.polygon_end = undefined;
    }
  }

  polygonFinish(fila, columna) {
    this.polygonUpdate(fila, columna);

    if (this.polygon_end) {
      let result = {
        start_row: Math.min(this.polygon_begin[0], this.polygon_end[0]),
        finish_row: Math.max(this.polygon_begin[0], this.polygon_end[0]),
        start_column: Math.min(this.polygon_begin[1], this.polygon_end[1]),
        finish_column: Math.max(this.polygon_begin[1], this.polygon_end[1])
      };
      this.polygonClear();
      return result;
    } else {
      return null;
    }
  }

  polygonClear() {
    this.polygon_begin = this.polygon_end = undefined;
    this.polygon = false;
  }

  hoverRuler(fila, columna, mundo_ancho, mundo_alto) {
    // Coordenada para dibujar la primera casilla
    let origen = {x: 30, y: mundo_alto - 60};
    this.context.fillStyle = 'rgba(255,0,0,0.4)';
    // pinta de rojo sobre la zona gris
    this.context.fillRect(6, origen.y - (fila - this.primera_fila) * 30 + 3,
                     this.tamano_celda - 6, this.tamano_celda - 6);
    this.context.fillRect(origen.x + (columna - this.primera_columna) * 30 + 3,
                     origen.y + 30, this.tamano_celda - 6,
                     this.tamano_celda - 4);
  }

  hoverCorner(fila, columna, mundo_ancho, mundo_alto) {
    // Coordenada para dibujar la primera casilla
    let origen = {x: 30, y: mundo_alto - 60};
    this.context.fillStyle = 'rgba(255,0,0,0.5)';
    this.context.fillRect(origen.x + (columna - this.primera_columna) * 30 - 4,
                     origen.y - (fila - this.primera_fila) * 30 + 24, 10, 10);
    this.hoverRuler(fila, columna, mundo_ancho, mundo_alto);
  }

  hoverWall(fila, columna, orientacion, mundo_ancho, mundo_alto) {
    // Coordenada para dibujar la primera casilla
    let origen = {x: 30, y: mundo_alto - 60};
    this.context.fillStyle = 'rgba(255,0,0,0.5)';
    if (orientacion == 0) {  // oeste
      this.context.fillRect(origen.x + (columna - this.primera_columna) * 30 - 2,
                       origen.y - (fila - this.primera_fila) * 30 + 2, 6, 24);
    } else if (orientacion == 1) {  // norte
      this.context.fillRect(origen.x + (columna - this.primera_columna) * 30 + 4,
                       origen.y - (fila - this.primera_fila) * 30 - 4, 24, 6);
    } else if (orientacion == 2) {  // este
      this.context.fillRect(
          origen.x + (columna - this.primera_columna) * 30 - 2 + 30,
          origen.y - (fila - this.primera_fila) * 30 + 2, 6, 24);
    } else if (orientacion == 3) {  // sur
      this.context.fillRect(origen.x + (columna - this.primera_columna) * 30 + 4,
                       origen.y - (fila - this.primera_fila) * 30 + 26, 24, 6);
    }
    this.hoverRuler(fila, columna, mundo_ancho, mundo_alto);
  }

  hoverBuzzer(fila, columna, mundo_ancho, mundo_alto) {
    // Coordenada para dibujar la primera casilla
    let origen = {x: 30, y: mundo_alto - 60};
    this.context.fillStyle = 'rgba(255,0,0,0.5)';
    this.context.fillRect(origen.x + (columna - this.primera_columna) * 30 + 4,
                     origen.y - (fila - this.primera_fila) * 30 + 2,
                     this.tamano_celda - 6, this.tamano_celda - 6);
    this.hoverRuler(fila, columna, mundo_ancho, mundo_alto);
  }

  moveSouth() {
    if (this.primera_fila > 1) this.primera_fila--;
  }

  moveNorth() {
    if (this.primera_fila + this.num_filas - 2 < this.worldHeight)
      this.primera_fila++;
  }

  moveWest() {
    if (this.primera_columna > 1) this.primera_columna--;
  }

  moveEast() {
    if (this.primera_columna + this.num_columnas - 2 < this.worldWidth)
      this.primera_columna++;
  }

  calculateCell(x, y) {
    let mx = Math.max(x + this.margin, this.tamano_celda);
    let my = Math.max(y + this.margin, this.tamano_celda);

    let column = Math.floor(mx / this.tamano_celda) + this.primera_columna - 1;
    column = Math.min(column, this.worldWidth + 1);
    let row = Math.floor(my / this.tamano_celda) + this.primera_fila - 1;
    row = Math.min(row, this.worldHeight + 1);
    let x_in_wall = Math.floor((mx / this.margin) % 4) < 2;
    let y_in_wall = Math.floor((my / this.margin) % 4) < 2;
    let kind = CellType.Beeper;

    if (x_in_wall && y_in_wall)
      kind = CellType.Corner;
    else if (x_in_wall)
      kind = CellType.WestWall;
    else if (y_in_wall)
      kind = CellType.SouthWall;

    return {row: row, column: column, kind: kind, x: x, y: y};
  }
}
