<template>
  <button
    class="btn action"
    :title="title"
    :class="{'btn-primary': active}"
    @click="$emit('update:active', !active)"
  >
    <fa icon="crosshairs" />
  </button>
</template>

<script>
export default {
  name: 'TrackBtn',

  props: {
    active: Boolean,
  },

  computed: {
    title() {
      if (this.active) {
        return "Dejar de seguir a karel durante la ejecución";
      } else {
        return "Mantener a karel visible durante la ejecución";
      }
    },
  },
};
</script>

<style>
</style>
