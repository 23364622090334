<template>
  <div id="world-panel">
    <div class="action-bar">
      <button class="btn action" :disabled="!editable"><fa icon="plus" /> nuevo</button>
      <button class="btn action" :disabled="!editable"><fa icon="folder-open" /> abrir</button>
      <button class="btn action" :disabled="!editable"><fa icon="file-import" /> importar</button>
      <button class="btn action" :disabled="!editable"><fa icon="save" /> guardar in</button>
      <button class="btn action" :disabled="!editable"><fa icon="save" /> guardar out</button>
    </div>
    <div class="canvas-container">
      <WorldCanvas
        ref="world_canvas"
        :width="canvasWidth"
        :height="canvasHeight"
        :code="code"
        language="ruby"
        :state="state"
        :delay="delay"
        :trackKarel="trackKarel"
        @update:state="$emit('update:state', $event)"
        @update:beepers="beepers = $event"
      />
    </div>
    <div class="action-bar">
      <StopBtn @click="requestReset" />
      <PlayBtn :playing="playing" @update:playing="requestPlay" />
      <StepBtn @click="requestStep" />
      <BagBtn :beepers="beepers" :editable="editable" @update:beepers="requestSetBeepers" />
      <SpeedBtn v-model="delay" :min="0" :max="500" :step="10" />
      <TrackBtn :active="trackKarel" @update:active="toggleTrackKarel" />
    </div>
  </div>
</template>

<script>
import WorldCanvas from './WorldCanvas.vue';
import Vue from 'vue';
import PlayBtn from '../controls/PlayBtn.vue';
import StopBtn from '../controls/StopBtn.vue';
import StepBtn from '../controls/StepBtn.vue';
import BagBtn from '../controls/BagBtn.vue';
import SpeedBtn from '../controls/SpeedBtn.vue';
import TrackBtn from '../controls/TrackBtn.vue';
import { RunningState } from '../lib/constants.js';
import EditableMixin from '../mixins/EditableMixin.js';

export default {
  name: 'WorldPanel',

  mixins: [EditableMixin],

  props: {
    code: String,
    state: Number,
  },

  components: {
    WorldCanvas,
    PlayBtn,
    StopBtn,
    StepBtn,
    BagBtn,
    SpeedBtn,
    TrackBtn,
  },

  data: function () {
    return {
      canvasWidth: 600,
      canvasHeight: 400,
      beepers: 0,
      delay: 250,
      trackKarel: true,
    };
  },

  computed: {
    playing() {
      return this.state == RunningState.RUNNING;
    },
  },

  mounted: function () {
    Vue.nextTick(() => {
      let container = this.$el.getElementsByClassName('canvas-container')[0];

      this.canvasWidth = container.offsetWidth;
      this.canvasHeight = container.offsetHeight;
    });
  },

  methods: {
    requestPlay(playing) {
      if (playing) {
        this.$refs.world_canvas.requestPlay();
      } else {
        this.$refs.world_canvas.requestPause();
      }
    },

    requestReset() {
      this.$refs.world_canvas.requestReset();
    },

    requestStep() {
      this.$refs.world_canvas.requestStep();
    },

    requestSetBeepers(beepers) {
      this.$refs.world_canvas.requestSetBeepers(beepers);
    },

    recalcCanvasDimentions() {
      let canvasDiv = this.$el.getElementsByClassName('canvas-container')[0];

      this.canvasWidth = canvasDiv.offsetWidth;
      this.canvasHeight = canvasDiv.offsetHeight;
    },

    toggleTrackKarel(value) {
      this.trackKarel = value;
    },
  },
}
</script>

<style scoped>
#world-panel {
  background-color: #c6d8ff;
  display: flex;
  flex-direction: column;
}

.canvas-container {
  flex-grow: 1;
  overflow: hidden;
}
</style>
