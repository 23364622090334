/**
 * A class that holds the state of computation and executes opcodes.
 *
 * The Karel Virtual Machine is a simple, stack-based virtual machine with
 * a small number of opcodes, based loosely on the Java Virtual Machine.
 * All opcodes are represented as an array where the first element is the
 * opcode name, followed by zero or one parameters.
 */
class Runtime {
  constructor(world) {
    this.world = world;
    this.disableStackEvents = false;

    this.load([['HALT']]);
  }

  load(opcodes) {
    let opcode_mapping = [
      'HALT',
      'LINE',
      'LEFT',
      'WORLDWALLS',
      'ORIENTATION',
      'ROTL',
      'ROTR',
      'MASK',
      'NOT',
      'AND',
      'OR',
      'EQ',
      'EZ',
      'JZ',
      'JMP',
      'FORWARD',
      'WORLDBUZZERS',
      'BAGBUZZERS',
      'PICKBUZZER',
      'LEAVEBUZZER',
      'LOAD',
      'POP',
      'DUP',
      'DEC',
      'INC',
      'CALL',
      'RET',
      'PARAM'
    ];
    let error_mapping = ['WALL', 'WORLDUNDERFLOW', 'BAGUNDERFLOW', 'INSTRUCTION'];

    this.raw_opcodes = opcodes;
    let function_map = {};
    this.function_names = [];
    let function_idx = 0;
    this.program = new Int32Array(new ArrayBuffer(opcodes.length * 3 * 4));

    for (let i = 0; i < opcodes.length; i++) {
      this.program[3 * i] = opcode_mapping.indexOf(opcodes[i][0]);
      if (opcodes[i].length > 1) {
        this.program[3 * i + 1] = opcodes[i][1];
      }
      if (opcodes[i][0] == 'CALL') {
        if (!Object.prototype.hasOwnProperty.call(function_map, opcodes[i][2])) {
          function_map[opcodes[i][2]] = function_idx;
          this.function_names[function_idx++] = opcodes[i][2];
        }
        this.program[3 * i + 2] = function_map[opcodes[i][2]];
      } else if (opcodes[i][0] == 'EZ') {
        this.program[3 * i + 1] = error_mapping.indexOf(opcodes[i][1]);
        if (this.program[3 * i + 1] == -1) {
          throw new Error('Invalid error: ' + opcodes[i][1]);
        }
      }
    }
    this.reset();
  }

  reset() {
    this.state = {
      pc: 0,
      sp: -1,
      fp: -1,
      line: -1,
      ic: 0,
      stack: new Int32Array(new ArrayBuffer((0xffff * 16 + 40) * 4)),
      stackSize: 0,

      // Instruction counts
      moveCount: 0,
      turnLeftCount: 0,
      pickBuzzerCount: 0,
      leaveBuzzerCount: 0,

      // Flags
      jumped: false,
      running: true
    };
  }

  step() {
    while (this.state.running) {
      // TODO calling next might raise an exception, investigate
      if (this.program[3 * this.state.pc] == Runtime.LINE) {
        this.next();
        break;
      }
      this.next();
    }

    return this.state.running;
  }

  next() {
    if (!this.state.running) return;

    let world = this.world;

    if (this.state.ic >= world.maxInstructions) {
      this.state.running = false;
      this.state.error = 'INSTRUCTION';

      return false;
    } else if (this.state.stackSize >= this.world.maxStackSize) {
      this.state.running = false;
      this.state.error = 'STACK';

      return false;
    }

    let rot;
    let di = [0, 1, 0, -1];
    let dj = [-1, 0, 1, 0];
    let param, newSP, op1, op2;

    try {
      switch (this.program[3 * this.state.pc]) {
        case Runtime.HALT: {
          this.state.running = false;
          break;
        }

        case Runtime.LINE: {
          this.state.line = this.program[3 * this.state.pc + 1];
          break;
        }

        case Runtime.LEFT: {
          this.state.ic++;
          this.world.orientation--;
          if (this.world.orientation < 0) {
            this.world.orientation = 3;
          }
          this.world.dirty = true;
          this.state.turnLeftCount++;
          if (this.world.maxTurnLeft >= 0 &&
              this.state.turnLeftCount > this.world.maxTurnLeft) {
            this.state.running = false;
            this.state.error = 'INSTRUCTION';
          }
          break;
        }

        case Runtime.WORLDWALLS: {
          this.state.stack[++this.state.sp] = world.walls(world.i, world.j);
          break;
        }

        case Runtime.ORIENTATION: {
          this.state.stack[++this.state.sp] = world.orientation;
          break;
        }

        case Runtime.ROTL: {
          rot = this.state.stack[this.state.sp] - 1;
          if (rot < 0) {
            rot = 3;
          }
          this.state.stack[this.state.sp] = rot;
          break;
        }

        case Runtime.ROTR: {
          rot = this.state.stack[this.state.sp] + 1;
          if (rot > 3) {
            rot = 0;
          }
          this.state.stack[this.state.sp] = rot;
          break;
        }

        case Runtime.MASK: {
          this.state.stack[this.state.sp] = 1 << this.state.stack[this.state.sp];
          break;
        }

        case Runtime.NOT: {
          this.state.stack[this.state.sp] =
              (this.state.stack[this.state.sp] === 0) ? 1 : 0;
          break;
        }

        case Runtime.AND: {
          op2 = this.state.stack[this.state.sp--];
          op1 = this.state.stack[this.state.sp--];
          this.state.stack[++this.state.sp] = (op1 & op2) ? 1 : 0;
          break;
        }

        case Runtime.OR: {
          op2 = this.state.stack[this.state.sp--];
          op1 = this.state.stack[this.state.sp--];
          this.state.stack[++this.state.sp] = (op1 | op2) ? 1 : 0;
          break;
        }

        case Runtime.EQ: {
          op2 = this.state.stack[this.state.sp--];
          op1 = this.state.stack[this.state.sp--];
          this.state.stack[++this.state.sp] = (op1 == op2) ? 1 : 0;
          break;
        }

        case Runtime.EZ: {
          if (this.state.stack[this.state.sp--] === 0) {
            this.state.error =
                ['WALL',
                 'WORLDUNDERFLOW',
                 'BAGUNDERFLOW'][this.program[3 * this.state.pc + 1]];
            this.state.running = false;
          }
          break;
        }

        case Runtime.JZ: {
          this.state.ic++;
          if (this.state.stack[this.state.sp--] === 0) {
            this.state.pc += this.program[3 * this.state.pc + 1];
          }
          break;
        }

        case Runtime.JMP: {
          this.state.ic++;
          this.state.pc += this.program[3 * this.state.pc + 1];
          break;
        }

        case Runtime.FORWARD: {
          this.state.ic++;
          this.world.i += di[this.world.orientation];
          this.world.j += dj[this.world.orientation];
          this.world.dirty = true;
          this.state.moveCount++;
          if (this.world.maxMove >= 0 &&
              this.state.moveCount > this.world.maxMove) {
            this.state.running = false;
            this.state.error = 'INSTRUCTION';
          }
          break;
        }

        case Runtime.WORLDBUZZERS: {
          this.state.stack[++this.state.sp] =
              (this.world.buzzers(world.i, world.j));
          break;
        }

        case Runtime.BAGBUZZERS: {
          this.state.stack[++this.state.sp] = (this.world.bagBuzzers);
          break;
        }

        case Runtime.PICKBUZZER: {
          this.state.ic++;
          this.world.pickBuzzer(this.world.i, this.world.j);
          this.state.pickBuzzerCount++;
          if (this.world.maxPickBuzzer >= 0 &&
              this.state.pickBuzzerCount > this.world.maxPickBuzzer) {
            this.state.running = false;
            this.state.error = 'INSTRUCTION';
          }
          break;
        }

        case Runtime.LEAVEBUZZER: {
          this.state.ic++;
          this.world.leaveBuzzer(this.world.i, this.world.j);
          this.state.leaveBuzzerCount++;
          if (this.world.maxLeaveBuzzer >= 0 &&
              this.state.leaveBuzzerCount > this.world.maxLeaveBuzzer) {
            this.state.running = false;
            this.state.error = 'INSTRUCTION';
          }
          break;
        }

        case Runtime.LOAD: {
          this.state.stack[++this.state.sp] = this.program[3 * this.state.pc + 1];
          break;
        }

        case Runtime.POP: {
          this.state.sp--;
          break;
        }

        case Runtime.DUP: {
          this.state.stack[++this.state.sp] = this.state.stack[this.state.sp - 1];
          break;
        }

        case Runtime.DEC: {
          this.state.stack[this.state.sp]--;
          break;
        }

        case Runtime.INC: {
          this.state.stack[this.state.sp]++;
          break;
        }

        case Runtime.CALL: {
          this.state.ic++;
          // sp, pc, param
          param = this.state.stack[this.state.sp--];
          newSP = this.state.sp;

          this.state.stack[++this.state.sp] = this.state.fp;
          this.state.stack[++this.state.sp] = newSP;
          this.state.stack[++this.state.sp] = this.state.pc;
          this.state.stack[++this.state.sp] = param;

          this.state.fp = newSP + 1;
          this.state.pc = this.program[3 * this.state.pc + 1];
          this.state.jumped = true;
          this.state.stackSize++;

          if (this.state.stackSize >= this.world.maxStackSize) {
            this.state.running = false;
            this.state.error = 'STACK';
          }

          break;
        }

        case Runtime.RET: {
          if (this.state.fp < 0) {
            this.state.running = false;
            break;
          }
          this.state.pc = this.state.stack[this.state.fp + 2];
          this.state.sp = this.state.stack[this.state.fp + 1];
          this.state.fp = this.state.stack[this.state.fp];
          this.state.stackSize--;

          break;
        }

        case Runtime.PARAM: {
          this.state.stack[++this.state.sp] =
              this.state
                  .stack[this.state.fp + 3 + this.program[3 * this.state.pc + 1]];
          break;
        }

        default: {
          this.state.running = false;

          this.state.error = 'INVALIDOPCODE';
          return false;
        }
      }

      if (this.state.jumped) {
        this.state.jumped = false;
      } else {
        this.state.pc++;
      }
    } catch (e) {
      this.state.running = false;

      throw e;
    }

    return true;
  }
}

Runtime.HALT = 0;
Runtime.LINE = 1;
Runtime.LEFT = 2;
Runtime.WORLDWALLS = 3;
Runtime.ORIENTATION = 4;
Runtime.ROTL = 5;
Runtime.ROTR = 6;
Runtime.MASK = 7;
Runtime.NOT = 8;
Runtime.AND = 9;
Runtime.OR = 10;
Runtime.EQ = 11;
Runtime.EZ = 12;
Runtime.JZ = 13;
Runtime.JMP = 14;
Runtime.FORWARD = 15;
Runtime.WORLDBUZZERS = 16;
Runtime.BAGBUZZERS = 17;
Runtime.PICKBUZZER = 18;
Runtime.LEAVEBUZZER = 19;
Runtime.LOAD = 20;
Runtime.POP = 21;
Runtime.DUP = 22;
Runtime.DEC = 23;
Runtime.INC = 24;
Runtime.CALL = 25;
Runtime.RET = 26;
Runtime.PARAM = 27;

export default Runtime;
